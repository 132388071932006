<template>
  <nh-drawer
    v-model="drawerVisible"
    :before-close="handleClose"
    destroy-on-close
    :append-to-body="true"
    title="详情"
    size="1111px"
    :track="{
      name_zh: `出库管理/预约提货计划/抽屉-详情`,
      trackName: $route.path
    }"
  >
    <div class="details-page">
      <div class="block">
        <div class="title">
          <h3>预约提货详情</h3>
        </div>
        <div class="body store_info">
          <nh-dialog-form label-width="100px">
            <nh-dialog-form-item
              label="提货单号:"
              :span="8"
            >
              {{ bookingInfo.ladingOrderNo }}
            </nh-dialog-form-item>
            <nh-dialog-form-item
              label="提货方式:"
              :span="8"
            >
              {{ PICKUP_TYPE_ENUM[bookingInfo.pickupType] }}
            </nh-dialog-form-item>
            <nh-dialog-form-item
              label="申请提货时间:"
              :span="8"
            >
              {{ dateTimeFilter(bookingInfo.bookingTime) }}
            </nh-dialog-form-item>
            <!-- 因列表内的数据没有唯一建  所以使用index -->
            <div
              v-for="(item, index) in bookingInfo.carInfo"
              :key="index"
              class="margin-bottom width-100"
            >
              <nh-dialog-form-item
                label="车牌号:"
                :span="5"
              >
                {{ item.plateNo }}
              </nh-dialog-form-item>
              <nh-dialog-form-item
                label="司机姓名:"
                :span="5"
              >
                {{ item.diverName }}
              </nh-dialog-form-item>
              <nh-dialog-form-item
                label="开始装车时间:"
                :span="7"
              >
                {{ dateTimeFilter(item.beginLoadingTime) }}
              </nh-dialog-form-item>
              <nh-dialog-form-item
                label="发运时间:"
                :span="7"
              >
                {{ dateTimeFilter(item.beginLoadingTime) }}
              </nh-dialog-form-item>
            </div>
          </nh-dialog-form>
        </div>
      </div>
      <div class="block">
        <div class="title">
          <h3>预约信息</h3>
        </div>
        <div class="body collapse">
          <el-collapse v-model="activeNames" accordion>
            <el-collapse-item v-for="booking in bookingInfo.deliverOrders" :key="booking.id">
              <template #title>
                <div class="collapse-title">
                  <p>出库单号：{{ booking.deliverOrderNo }}</p>
                  <p>外部单号：{{ booking.externalOrderNo }}</p>
                  <p>货主：{{ booking.shipperName }}</p>
                  <el-tooltip
                    effect="dark"
                    :content="booking.stationAddress"
                    placement="top"
                  >
                    <p class="station-address">
                      站点：{{ booking.stationName }}
                    </p>
                  </el-tooltip>
                </div>
              </template>
              <div class="table-box">
                <el-table
                  ref="table"
                  :data="booking.goods"
                  class="width-100"
                  border
                >
                  <el-table-column
                    v-for="item in TABLECOLUMN()"
                    :key="item.field"
                    :prop="item.field"
                    :label="item.label"
                  >
                    <template #default="scope">
                      <span v-if="item.prop === 'temperatureLayer'">{{
                        filterLabel(scope.row[item.prop])
                      }}</span>
                      <span v-else-if="item.format">{{ item.format(scope.row) }}</span>
                      <span v-else>{{ scope.row[item.prop] }}</span>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
  </nh-drawer>
</template>
<script>
import moment from 'moment';
import { YMDHMS } from '@/constant/timeFormat';
import { TABLECOLUMN } from './fileds';
import { TEMPERATURE_LAYER_ENUM, PICKUP_TYPE_ENUM } from '../constant';
import { pickupPlanDetail } from '../api';

export default {
  name: 'AuditedDetail',
  data() {
    return {
      drawerVisible: false,
      TABLECOLUMN,
      PICKUP_TYPE_ENUM,
      StoreData: {},
      activeNames: '',
      tableData: [],
      bookingInfo: {},
    };
  },
  methods: {
    async pickupPlanDetail(ladingOrderId) {
      this.drawerVisible = true;
      this.bookingInfo = await pickupPlanDetail({ ladingOrderId });
    },
    filterLabel(val) {
      return TEMPERATURE_LAYER_ENUM[val] || '';
    },
    dateTimeFilter(val) {
      return val ? moment(val).format(YMDHMS) : '';
    },
    handleClose() {
      this.drawerVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.details-page {
  min-width: 768px;
}

.block {
  :deep(.el-form-item) {
    margin: 0;
  }
}

.title {
  border-left: 3px solid #02a7f0;
  padding-left: 10px;

  h3 {
    font-size: 14px;
  }
}

.store_info {
  margin-top: 20px;
}

.body {
  padding-left: 12px;
}

.body.collapse {
  padding: 12px 0;
}

.collapse-title {
  flex: 5;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 12px;

  & > p {
    flex: 1;
  }
}

.table-box {
  padding: 12px 12px 0 12px;
}

.width-100 {
  width: 100%;
}

.margin-bottom {
  margin-bottom: 12px;
}

.margin-left-16 {
  margin-bottom: 16px;
}

.margin-left {
  margin-bottom: -16px;
}

.station-address {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
