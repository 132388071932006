<template>
  <div class="right-table-legend">
    <p>{{ time }}</p>
    <div
      v-for="(item) in DOCK_LEGENDS"
      :key="item.text"
      class="legend"
    >
      <div class="color" :style="{backgroundColor:item.color,borderColor:item.borderColor}" />
      <p>{{ item.text }}</p>
    </div>
  </div>
</template>
<script>
import {
  DOCK_LEGENDS,
} from '../constant';

export default {
  name: 'DockTableLegend',
  components: {
  },
  filters: {
  },
  mixins: [],
  props: {
    time: {
      type: String,
      default: () => '',
    },
  },

  data() {
    return {
      DOCK_LEGENDS,
    };
  },
  computed: {
  },
  created() {
  },
  methods: {
  },
};
</script>
<style lang="scss" scoped>
.right-table-legend {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px;

  & > p {
    width: 150px;
  }

  .legend {
    margin: 0 32px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .color {
      width: 40px;
      height: 20px;
      margin-right: 8px;
      border: 1px solid transparent;
    }

    p {
      color: #606266;
      border: 1px solid transparent;
      border-radius: 2px;
    }
  }
}
</style>
