export const StoreInfo = [{
  label: '派车单号：',
  prop: 'id',
}, {
  label: '车牌号：',
  prop: 'warehouseName',
}, {
  label: '司机姓名：',
  prop: 'warehouseCode',
}, {
  label: '提货方式：',
  prop: 'temperature',
}, {
  label: '提货单位：',
  prop: 'range',
}, {
  label: '申请提货时间：',
  prop: 'area',
}];
export function TABLECOLUMN() {
  return [{
    label: '货主货品编码',
    prop: 'shipperGoodsCode',
    width: 180,
  }, {
    label: '货品名称',
    prop: 'goodsName',
    width: 180,
  }, {
    label: '温层',
    prop: 'temperatureLayer',
    width: 180,
  }, {
    label: '默认单位',
    prop: 'defaultUnit',
    width: 180,
  }, {
    label: '换算率',
    prop: 'conversionRate',
    width: 180,
  }, {
    label: '预约数量',
    prop: 'bookingNum',
    width: 180,
    format: (rowData) => {
      const filed = 'bookingNum';
      if (Number.isNaN(rowData[filed]) || Number.isNaN(rowData[filed])) {
        return rowData[filed];
      }
      const bigUnit = rowData[filed] / rowData.conversionRate;
      const smallUnit = rowData[filed] % rowData.conversionRate;
      let result = '';
      if (bigUnit >= 1) {
        result = `${Number.parseInt(bigUnit, 10)}${rowData.bigUnit}`;
      }
      if (smallUnit >= 1) {
        result += `${smallUnit}${rowData.smallUnit}`;
      }
      return result;
    },
  }];
}
