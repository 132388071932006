import { FormType } from '@/constant/form';
import moment from 'moment';
import { YMDHMS } from '@/constant/timeFormat';

function timeFormat(time) { return time ? moment(time).format(YMDHMS) : ''; }
export function TABLECOLUMN() {
  return [{
    label: '提货单号',
    prop: 'ladingOrderNo',
    minWidth: 180,
  }, {
    label: '车牌号',
    prop: 'cars',
    minWidth: 180,
    formatter: (row) => {
      let text = '';
      if (row.cars) {
        row.cars.forEach((item) => {
          text += `${item.plateNo ? `${item.plateNo}:` : ''}${item.driverName ? `${item.driverName};` : ''}`;
        });
      }
      return text;
    },
  }, {
    label: '订单数',
    prop: 'bookingOrderNum',
    minWidth: 180,
  }, {
    label: '货品量',
    prop: 'bookingBigNum',
    minWidth: 180,
    formatter: (row) => `整箱${row.bookingBigNum}拆零${row.bookingSmallNum}`,
  }, {
    label: '创建时间',
    prop: 'createTime',
    minWidth: 200,
    formatter: (row) => timeFormat(row.createTime),
  }, {
    label: '预约提货时间',
    prop: 'bookingTime',
    minWidth: 200,
    formatter: (row) => timeFormat(row.bookingTime),
  }, {
    label: '开始装车时间',
    prop: 'beginLoadingTime',
    minWidth: 200,
    formatter: (row) => timeFormat(row.beginLoadingTime),
  }, {
    label: '集货月台',
    prop: 'dockName',
    minWidth: 180,
  }, {
    label: '线路编号',
    prop: 'lineId',
    minWidth: 180,
  }, {
    label: '线路名称',
    prop: 'lineName',
    minWidth: 180,
  }, {
    label: '操作',
    prop: 'action',
    fixed: 'right',
    width: 100,
  }];
}
export function serchFields() {
  return [
    {
      label: '提货单号',
      prop: 'ladingOrderNo',
      component: FormType.INPUT,
    }, {
      label: '车牌号',
      prop: 'plateNo',
      component: FormType.INPUT,
    }, {
      label: '预约提货时间',
      prop: 'afterBookingTime',
      component: FormType.DATE_PICKER,
      componentAttrs: {
        type: 'daterange',
        default: [],
        valueFormat: YMDHMS,
      },
    },
  ];
}
