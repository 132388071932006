export const DOCK_TIME_ROWS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
export const DOCK_LEGEND_COLOR = ['rgba(255, 255, 255, 1)', 'rgba(245, 108, 108, 0.2)', 'rgba(249, 231, 204, 1)', 'rgba(6, 184, 128, 0.2)'];
export const SHIP_STATUS = {
  PRE_LOADING: DOCK_LEGEND_COLOR[1],
  LOADING: DOCK_LEGEND_COLOR[2],
  SHIPPED: DOCK_LEGEND_COLOR[3],
};
export const DOCK_LEGENDS = [
  { text: '空闲', color: DOCK_LEGEND_COLOR[0], borderColor: 'rgba(220, 223, 230, 1)' },
  { text: '已预约', color: DOCK_LEGEND_COLOR[1], borderColor: 'transparent' },
  { text: '装车中', color: DOCK_LEGEND_COLOR[2], borderColor: 'transparent' },
  { text: '装车完成', color: DOCK_LEGEND_COLOR[3], borderColor: 'transparent' },
];

export const SHIP_STATUS_LIST = [
  {
    label: '待装车',
    value: 'PRE_LOADING',
  },
  {
    label: '装车中',
    value: 'LOADING',
  },
  {
    label: '已发运',
    value: 'SHIPPED',
  },
];
export const DOCK_TIME_CELL_WIDTH = 130;
export const DOCK_CELL_WIDTH = 100;
export const DOCK_CELL_HEIGHT = 100;
export const PICKUP_TYPE_ENUM = {
  CUSTOMER_DELIVERY: '客户自提',
  TMS_DELIVERY: 'TMS配送',
};
export const TEMPERATURE_LAYER_ENUM = {
  NORMAL: '常温',
  REFRIGERATE: '冷藏',
  COLD: '冷冻',
  CONSTANT: '恒温',
};
