<template>
  <pl-block>
    <PlForm
      v-model="formData"
      mode="search"
      :fields="serchFields()"
      @submit="handleQueryFrom"
      @reset="handleQueryFrom"
    />
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="selfPagination"
      :data="auditedData"
      :columns="TABLECOLUMN()"
      :show-table-setting="true"
    >
      <template #ladingOrderNo="{row}">
        <nh-link
          :to="{}"
          :text="row.ladingOrderNo"
          @click="handlePickupPlanDetail(row)"
        />
      </template>
      <template #action="{row}">
        <nh-button
          v-if="!row.beginLoadingTime"
          type="text"
          @click="handleAdjustDock(row)"
        >
          提货计划调整
        </nh-button>
      </template>
    </PlTable>
  </pl-block>
  <PickupPlanDetail ref="pickupPlanDetail" />
</template>
<script>
import { TABLECOLUMN, serchFields } from './fileds';
import PickupPlanDetail from '../detail/index.vue';

export default {
  name: 'AuditedChecked',
  components: { PickupPlanDetail },
  props: {
    pagination: {
      type: Object,
      default: () => {},
    },
    auditedData: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['auditedSearch', 'auditedSearch', 'dockUpdate'],
  data() {
    return {
      serchFields,
      TABLECOLUMN,
      formData: {},
      selfPagination: this.pagination,
    };
  },
  watch: {
    selfPagination() {
      this.handlePaginationChange(this.selfPagination);
    },
  },
  created() {},
  methods: {
    handleQueryFrom(data) {
      this.formData = data;
      // 调用表单重置 时间控件会跳到 1970 年，增加default 解决问题后 导致 重置 会携带默认值，所以在这里判定，只有一个值的时候即为重置
      const afterBookingTime = !(this.formData.afterBookingTime?.length)
        || (this.formData.afterBookingTime
          && this.formData.afterBookingTime instanceof Array
          && this.formData.afterBookingTime.length < 2);
      const afterDeliverTime = !(this.formData.afterDeliverTime?.length)
        || (this.formData.afterDeliverTime
          && this.formData.afterDeliverTime instanceof Array
          && this.formData.afterDeliverTime.length < 2);
      if (afterBookingTime) {
        this.formData.afterBookingTime = [];
      }
      if (afterDeliverTime) {
        this.formData.afterDeliverTime = [];
      }
      this.$emit('auditedSearch', { ...this.pagination, page: 1 }, this.formData);
    },
    handlePaginationChange(pagination) {
      this.$emit('auditedSearch', pagination, this.formData);
    },
    handleAdjustDock(rowData) {
      this.$emit('dockUpdate', rowData);
    },
    handlePickupPlanDetail(row) {
      this.$refs.pickupPlanDetail.pickupPlanDetail(row.id);
    },
  },
};
</script>
