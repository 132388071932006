import { wmsPlusHttp } from '@/utils/http/index';
/**
 * @description: 提货计划待审核分页
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11906
 */

export function pickupPlanPreAuditPage(data) {
  return wmsPlusHttp.post('/warehouse_management_system/pickup_plan/pre_audit/page', data);
}
/**
 * @description: 提货计划已审核分页
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11908
 */

export function pickupPlanAuditedPage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/pickup_plan/audited/page', data, { params });
}
/**
 * @description: 提货计划月台信息列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11910
 */

export function pickupPlanDockList(data) {
  return wmsPlusHttp.post('/warehouse_management_system/pickup_plan/dock/list', data);
}
/**
 * @description: 提货计划审核
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11912
 */

export function pickupPlanAudit(data) {
  return wmsPlusHttp.post('/warehouse_management_system/pickup_plan/audit', data);
}
/**
 * @description: 提货计划详情
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/11958
 */

export function pickupPlanDetail(data) {
  return wmsPlusHttp.post('/warehouse_management_system/pickup_plan/detail', data);
}
/**
 * @description: 调整月台信息
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/12122
 */

export function pickupPlanDockUpdate(data) {
  return wmsPlusHttp.post('/warehouse_management_system/pickup_plan/dock/update', data);
}

/**
 * @description: 仓库月台 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11000
 */

export function warehousePlatformPage(params, data) {
  return wmsPlusHttp.post('/warehouse_foundation/dock/page', data, { params });
}
