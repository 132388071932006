<template>
  <el-table
    ref="table"
    :data="tableData"
    :height="`calc(100vh - 300px)`"
    class="dock-view-table width-100"
    border
  >
    <el-table-column
      v-for="item in tableHeader"
      :key="item.field"
      :prop="item.field"
      :label="item.label"
      :width="item.width"
      :fixed="item.fixed"
      :class-name="item.field === 'bookingTimeFormat' ? 'booking-time-format-col' : ''"
    >
      <template v-if="item.field === 'bookingTimeFormat'" #header>
        <canvas
          ref="bookingTimeFormat"
          class="booking-time-format"
        />
      </template>
      <template #default="scope">
        <div v-if="item.field === 'bookingTimeFormat'" class="cell inside-cell time-col">
          <span>{{ scope.row.bookingTimeFormat }}</span>
        </div>
        <div v-else>
          <div v-for="carItem in scope.row[`dock${item.id}`].cars" :key="carItem.driverId">
            <el-tooltip effect="light" placement="top">
              <template #content>
                <div class="tooltip-inside">
                  <p>车辆：{{ carItem.plateNo }}</p>
                  <p>预约时间：{{ dateTimeFilter(carItem.bookingTime) }}</p>
                  <p>装车时间：{{ dateTimeFilter(carItem.beginLoadingTime) }}</p>
                  <p>发运时间：{{ dateTimeFilter(carItem.deliverTime) }}</p>
                </div>
              </template>
              <div
                class="cell inside-cell"
                :style="`background-color:${SHIP_STATUS[carItem.shipStatus]}`"
              >
                <div class="inside-cell-car-number">
                  <b> {{ carItem.plateNo }}</b>
                </div>
                <div class="inside-cell-car-time">
                  {{ showBookingTime(carItem.bookingTime) }}
                </div>
              </div>
            </el-tooltip>
          </div>
        </div>
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
import moment from 'moment';
import { YMDHMS, HHMM } from '@/constant/timeFormat';
import { DOCK_CELL_WIDTH, DOCK_CELL_HEIGHT, SHIP_STATUS } from '../constant';

export default {
  name: 'DockTableView',
  mixins: [],
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    tableHeader: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      DOCK_CELL_WIDTH,
      DOCK_CELL_HEIGHT,
      SHIP_STATUS,
    };
  },
  mounted() {
    this.$nextTick(() => {
      // 防止 dom 还没生成
      this.dragTableHeader();
    });
  },
  methods: {
    scrollTo(index = 0) {
      const refName = 'table';
      if (!refName || !this.$refs[refName]) return; // 不存在表格的ref vm 则返回
      const vmEl = this.$refs[refName].$el;
      if (!vmEl) return;
      // 计算滚动条的位置
      const allRows = vmEl.querySelectorAll('.el-table__body tr');
      const scrollParent = vmEl.querySelector('.el-table__body-wrapper');
      let heightSum = 0;
      for (let i = 0, len = allRows.length; i < len; i += 1) {
        // 当 遍历到 指定行时 不再遍历
        if (i < index - 1) {
          heightSum += allRows[i].getBoundingClientRect().height;
        } else {
          break;
        }
      }

      scrollParent.scrollTo(0, heightSum);
      // 每次滚动 即为重新渲染 重新渲染表格的同时 要重新渲染 斜线表头
      this.$nextTick(() => {
        // 防止 dom 还没生成
        this.dragTableHeader();
      });
    },
    // Canvas 绘制斜线表头
    dragTableHeader() {
      if (this.$refs.bookingTimeFormat) {
        const dom = this.$refs.bookingTimeFormat[0] || this.$refs.bookingTimeFormat;
        const ctx = dom.getContext('2d');
        ctx.beginPath();
        ctx.strokeStyle = 'rgba(220, 223, 230, 1)';
        ctx.moveTo(0, 0);
        ctx.lineTo(500, 120);
        ctx.moveTo(0, 0);
        ctx.lineTo(550, 550);
        ctx.stroke();
        // 绘制字体
        ctx.font = '30px "微软雅黑"';
        ctx.fillStyle = 'rgba(96, 98, 102, 1)';
        ctx.textBaseline = 'top';
        ctx.fillText('时间', 20, 100);
        ctx.fillText('预约情况', 150, 90);
        ctx.fillText('月台', 200, 8);
      }
    },
    dateTimeFilter(val) {
      return val ? moment(val).format(YMDHMS) : '';
    },
    showBookingTime(val) {
      if (val) {
        return moment(val).format(HHMM);
      }
      return '';
    },
  },
};
</script>
<style lang="scss" scoped>
.cell {
  min-height: 64px;
}

.inside-cell {
  & > div {
    width: 100%;
    text-align: center;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    color: #606266;
  }

  & > .inside-cell-car-time {
    text-align: right;
  }
}

.time-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

:deep(.el-table__body) .el-table__row > td {
  padding: 0;
}

:deep(.el-table__body) .el-table__row > td > .cell {
  padding: 0;
}

:deep(.el-tooltip__popper.is-light) {
  border-color: #dcdfe6;
}

.tooltip-inside {
  width: 200px;

  & > p {
    padding: 4px 0;
  }
}

.booking-time-format {
  height: 64px;
  width: 100%;
}
</style>
<style lang="scss">
.el-table th.booking-time-format-col {
  padding: 0;

  & > div.cell {
    padding: 0;
    height: 64px;
  }
}

.dock-view-table .el-table__header .has-gutter th.gutter:last-of-type {
  border-bottom: 1px solid transparent;
  border-bottom-width: 1px;
}

.width-100 {
  width: 100%;
}
</style>
