<template>
  <div>
    <nh-dialog
      v-model="visible"
      title="调整提货计划"
      custom-class="custom-dialog_70"
      :before-close="handleClose"
      :track="{
        name_zh: `出库管理/预约提货计划/弹窗-调整提货计划`,
        trackName: $route.path
      }"
    >
      <el-form
        ref="updateForm"
        :model="updateForm"
        :rules="rules"
        label-width="120px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="提货单号" prop="ladingOrderNo">
              <p>
                {{ updateForm.ladingOrderNo }}
              </p>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="预约提货时间" prop="bookingTime">
              <p>
                {{ dateTimeFilter(updateForm.bookingTime) }}
              </p>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="提货方式" prop="pickupType">
              <p>
                {{ PICKUP_TYPE_ENUM[updateForm.pickupType]||'' }}
              </p>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          v-for="(item, index) in updateForm.cars"
          :key="index"
          :gutter="20"
        >
          <el-col :span="7">
            <el-form-item
              label="车牌号:"
              :prop="`cars[${index}].plateNo`"
              :rules="[{ required: true, message: '请输入车牌号', trigger: 'blur' },]"
            >
              <el-input
                v-model="item.plateNo"
                :disabled="item.shipStatus === SHIP_STATUS_LIST[1].value ||
                  item.shipStatus === SHIP_STATUS_LIST[2].value"
              />
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-tag v-if="item.shipStatus">
              {{ filterLabel(item.shipStatus,SHIP_STATUS_LIST) }}
            </el-tag>
          </el-col>
          <el-col :span="7">
            <el-form-item
              label="司机姓名:"
              :prop="`cars[${index}].driverName`"
              :rules="[{ required: true, message: '请输入司机姓名', trigger: 'blur' },]"
            >
              <el-input
                v-model="item.driverName"
                :disabled="item.shipStatus === SHIP_STATUS_LIST[1].value ||
                  item.shipStatus === SHIP_STATUS_LIST[2].value"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-button v-if="index === updateForm.cars.length-1" @click="addCar">
              + 车
            </el-button>
            <el-button
              :disabled="item.shipStatus === SHIP_STATUS_LIST[1].value ||
                item.shipStatus === SHIP_STATUS_LIST[2].value"
              @click="deleteCar(index)"
            >
              - 车
            </el-button>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="原集货月台:" prop="dockName">
              <p>
                {{ updateForm.dockName }}
              </p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="分配集货月台:" prop="newDock">
              <el-select
                v-model="updateForm.newDock"
                placeholder="请选择..."
                value-key="id"
              >
                <el-option
                  v-for="(item) in deliverAreaDocks"
                  :key="item.id"
                  :label="item.dockCode"
                  :value="item"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <DockTable ref="dockTable" :basic-docks="basicDocks" />
      <template #footer>
        <span class="dialog-footer">
          <nh-button @click="handleClose">关 闭</nh-button>
          <nh-button
            type="primary"
            :loading="loading.pagePickupPlanDockUpdate"
            @click="handleSubmit('updateForm')"
          >确 定</nh-button>
        </span>
      </template>
    </nh-dialog>
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import moment from 'moment';
import { YMDHMS } from '@/constant/timeFormat';
import DockTable from '../dockTable/index.vue';
import { PICKUP_TYPE_ENUM, SHIP_STATUS_LIST } from '../constant';
import {
  pickupPlanDockUpdate,
} from '../api';

export default {
  name: 'AuditedDockUpdate',
  components: { DockTable },
  mixins: [loadingMixin],
  props: {
    basicDocks: {
      type: Object,
      default: () => {},
    },
    deliverAreaDocks: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['submitSuccess'],
  data() {
    return {
      PICKUP_TYPE_ENUM,
      SHIP_STATUS_LIST,
      updateForm: {
        cars: [],
        newDock: {},
      },
      rules: {
        newDock: [
          { required: true, message: '请选择分配集货月台', trigger: 'blur' },
        ],
        // plateNo: [
        //   { required: true, message: '请输入车牌号', trigger: 'blur' },
        // ],
      },
      visible: false,
      loading: {
        pagePickupPlanDockUpdate: false,
      },
    };
  },
  created() {},
  methods: {
    dateTimeFilter(val) {
      return val ? moment(val).format(YMDHMS) : '';
    },
    init(info) {
      this.updateForm = {
        ...info,
      };
      this.visible = true;
    },
    handleClose() {
      this.$refs.updateForm.resetFields();
      this.visible = false;
    },
    addCar() {
      this.updateForm.cars.push({});
    },
    deleteCar(index) {
      this.updateForm.cars.splice(index, 1);
      if (this.updateForm.cars.length === 0) {
        this.addCar();
      }
    },
    async  pagePickupPlanDockUpdate(data) {
      await pickupPlanDockUpdate(data);
    },
    handleSubmit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const data = {
            dockId: this.updateForm.newDock.id,
            ladingOrderId: this.updateForm.id,
            dockCode: this.updateForm.newDock.dockCode,
            // operatorId: '',
            // operatorName: '',
            dockName: this.updateForm.newDock.dockCode,
            carInfos: [...this.updateForm.cars],
          };
          await this.pagePickupPlanDockUpdate(data);
          this.$message({ type: 'success', message: '调整成功!' });
          this.$emit('submitSuccess', data);
        }
      });
    },
    filterLabel(val, arr) {
      const filterArr = arr.find((item) => item.value === val);
      return filterArr ? filterArr.label : '';
    },
  },
};
</script>
