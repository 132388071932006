<template>
  <div>
    <el-dialog
      v-model="visible"
      title="预约审核"
      width="50%"
      :before-close="handleClose"
    >
      <el-form
        ref="checkForm"
        :model="checkForm"
        :rules="rules"
        label-width="120px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="提货单号" prop="ladingOrderNo">
              <p>
                {{ checkForm.ladingOrderNo }}
              </p>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="提货方式" prop="pickupType">
              <p>
                {{ PICKUP_TYPE_ENUM[checkForm.pickupType] }}
              </p>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请提货时间" prop="bookingTime">
              <p>
                {{ dateTimeFilter(checkForm.bookingTime) }}
              </p>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-for="(item) in checkForm.cars" :key="item.driverId">
          <el-col :span="12">
            <el-form-item label="车牌号:" prop="plateNo">
              <p>
                {{ item.plateNo }}
              </p>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="司机姓名:" prop="driverName">
              <p>
                {{ item.driverName }}
              </p>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="分配集货月台:" prop="dock">
          <el-select
            v-model="checkForm.dock"
            placeholder="请选择..."
            value-key="id"
          >
            <el-option
              v-for="(item) in deliverAreaDocks"
              :key="item.id"
              :label="item.dockCode"
              :value="item"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="确认提货时间:" prop="bookingTime">
          <el-date-picker
            v-model="checkForm.bookingTime"
            type="datetime"
            :disabled-date="bookingTimeOptions"
            placeholder="选择提货时间"
          />
        </el-form-item>
        <el-form-item label="备注:" prop="dockAuditRemark">
          <el-input
            v-model="checkForm.dockAuditRemark"
            placeholder="请输入备注"
            type="textarea"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <nh-button @click="handleClose">关 闭</nh-button>
          <nh-button
            @click="handleReset('checkForm')"
          >重 置</nh-button>
          <nh-button
            type="primary"
            :loading="loading.pagePickupPlanAudit"
            @click="handleSubmit('checkForm')"
          >确 定</nh-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>
<script>
import loadingMixin from '@thales/loading';
import moment from 'moment';
import { YMDHMS } from '@/constant/timeFormat';
import { PICKUP_TYPE_ENUM } from '../constant';
import {
  pickupPlanAudit,
} from '../api';

export default {
  name: 'PreAuditCheck',
  mixins: [loadingMixin],
  props: {
    deliverAreaDocks: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['submitSuccess'],
  data() {
    return {
      PICKUP_TYPE_ENUM,
      checkForm: {
        cars: [],
      },
      checkFormCope: {},
      rules: {
        dock: [
          { required: true, message: '请输入分配集货月台', trigger: 'blur' },
        ],
        dockId: [
          { required: true, message: '请输入分配集货月台', trigger: 'blur' },
        ],
        bookingTime: [
          { required: true, message: '请输入确认提货时间', trigger: 'blur' },
        ],
        dockAuditRemark: [
          { required: false, message: '备注', trigger: 'change' },
        ],
      },
      visible: false,
      bookingTimeOptions: (time) => time < moment().subtract(1, 'days').valueOf(),
      loading: {
        pagePickupPlanAudit: false,
      },
    };
  },
  watch: {
    'checkForm.dock': {
      handler(newValue) {
        if (newValue && newValue.id) {
          this.checkForm.dockId = newValue.id;
          this.checkForm.dockCode = newValue.dockCode;
          this.checkForm.dockName = newValue.dockCode;
        }
      },
      deep: true,
    },
  },
  created() {},
  methods: {
    dateTimeFilter(val) {
      return val ? moment(val).format(YMDHMS) : '';
    },
    init(info) {
      this.$nextTick(() => {
        this.checkForm = {
          ...info,
        };
      });
      this.visible = true;
    },
    handleClose() {
      this.$refs.checkForm.resetFields();
      this.visible = false;
    },
    async pagePickupPlanAudit(data) {
      await pickupPlanAudit({ ...data, ladingOrderId: data.id });
    },
    async handleSubmit(formName) {
      const valid = await this.$refs[formName].validate();
      if (valid) {
        if (this.checkForm.bookingTime >= moment().valueOf()) {
          await this.pagePickupPlanAudit(this.checkForm);
          this.$message({ type: 'success', message: '审核成功!' });
          this.$emit('submitSuccess', this.checkForm);
          this.handleClose();
        } else {
          this.$message({ type: 'warning', message: '请选择当前时间之后的时间' });
        }
      }
    },
    handleReset(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
