<template>
  <div class="card" @click="handleCardClick">
    <div class="card-row">
      <p>提货单号：</p><p>{{ card.ladingOrderNo }}</p>
    </div>
    <div class="card-row">
      <p>申请提货时间：</p><p>{{ dateTimeFilter(card.bookingTime) }}</p>
    </div>
    <div class="card-row">
      <p>车辆数：</p><p>{{ card.carNum }}</p>
    </div>
    <div class="card-row last">
      <nh-button
        size="mini"
        type="primary"
        @click="handleCheck"
      >
        审核
      </nh-button>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { YMDHMS } from '@/constant/timeFormat';

export default {
  name: 'PreAuditListCard',
  props: {
    card: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['item-click', 'check'],
  data() {
    return {
      loading: {
        getTableData: false,
      },
    };
  },
  methods: {
    handleCardClick() {
      this.$emit('item-click', this.card);
    },
    handleCheck() {
      this.$emit('check', this.card);
    },
    dateTimeFilter(val) {
      return moment(val).format(YMDHMS);
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  background-color: #fff;
  box-shadow: 0 1px 6px 0 rgba(0, 25, 55, 0.1);
  border-radius: 4px;
  padding: 12px 16px 12px 16px;
  margin-bottom: 24px;
  cursor: pointer;

  &:last-of-type {
    margin-bottom: 0;
  }

  .card-row {
    padding: 4px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .card-row.last {
    justify-content: flex-end;
  }
}
</style>
