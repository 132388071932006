<template>
  <div class="page-box">
    <el-tabs v-model="tabModel">
      <el-tab-pane label="待审核" name="1">
        <el-row :gutter="24" class="pre-audit-body">
          <el-col
            :span="6"
            class="list-box pre-audit"
            @scroll="handleScroll($event)"
          >
            <h3>预约提货确认</h3>
            <PreAuditListCard
              v-for="(item) in preAuditList"
              :key="item.id"
              :card="item"
              @item-click="handleCardClick"
              @check="handleCardCheck"
            />
          </el-col>
          <el-col
            :span="17"
            :offset="1"
            class="table-box"
          >
            <DockTable
              ref="dockTable"
              v-loading="loading.pickupPlanDockList"
              :basic-docks="preAuditeddockBasicInfo"
            />
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="已审核" name="2">
        <div class="audited-body">
          <CheckedTable
            v-loading="loading.pickupPlanAuditedPage"
            :pagination="pagination"
            :audited-data="auditedTableData"
            :tab-model="tabModel"
            @auditedSearch="handleAuditedSearch"
            @dockUpdate="handleDockUpdate"
          />
        </div>
      </el-tab-pane>
    </el-tabs>
    <PreAuditCheckDialog
      ref="preAuditCheckDialog"
      :deliver-area-docks="deliverAreaDocks"
      @submitSuccess="handleCheckSubmit"
    />
    <DockUpdateDialog
      ref="dockUpdateDialog"
      :basic-docks="preAuditeddockBasicInfo"
      :deliver-area-docks="deliverAreaDocks"
      @submitSuccess="handleSubmitDockUpdate"
    />
  </div>
</template>
<script>
import moment from 'moment';
import loadingMixin from '@thales/loading';
import { YMDH, HH } from '@/constant/timeFormat';
import OutWarehouse from '@/constant/pageNames/outWarehouse';
import {
  DOCK_TIME_CELL_WIDTH,
  DOCK_CELL_WIDTH,
  DOCK_TIME_ROWS,
} from './constant';
import PreAuditListCard from './preAuditListCard/index.vue';
import DockTable from './dockTable/index.vue';
import PreAuditCheckDialog from './preAuditCheckDialog/index.vue';
import CheckedTable from './checked/index.vue';
import DockUpdateDialog from './dockUpdateDialog/index.vue';
import mixin from './mixin';
import {
  pickupPlanPreAuditPage,
  pickupPlanAuditedPage,
  pickupPlanDockList,
  warehousePlatformPage,
} from './api';

export default {
  name: OutWarehouse.PICKUP_PLAN,
  components: {
    PreAuditListCard,
    DockTable,
    PreAuditCheckDialog,
    CheckedTable,
    DockUpdateDialog,
  },
  mixins: [loadingMixin, mixin],

  data() {
    return {
      loading: {
        pickupPlanAuditedPage: false,
        pickupPlanDockList: false,
      },
      tabModel: '1',
      preAuditList: [
      ],
      preAuditPagination: {
        page: 1,
        size: 10,
        total: 0,
      },
      auditedTableData: [],
      auditedFromData: {},
      preAuditeddockBasicInfo: {
        data: [],
        column: [],
        docksMap: {},
        time: '',
      },
      deliverAreaDocks: [],
      preAuditIsLoading: false,
    };
  },
  computed: {
  },
  watch: {
    pagination() {
      this.pickupPlanAuditedPage(this.auditedPagination, this.auditedFromData);
    },
  },
  created() {
    this.warehousePlatformPage();
    this.pickupPlanAuditedPage(this.pagination, {});
  },
  methods: {
    handleCardClick(cars) {
      this.pickupPlanDockList(moment(cars.bookingTime));
      // this.$refs.rightTable.scrollTo(31);
    },
    handleCardCheck(car) {
      this.$refs.preAuditCheckDialog.init(car);
    },
    handleCheckSubmit(checkForm) {
      this.pickupPlanAudit(checkForm);
    },
    handleScroll(event) {
      const ele = event.srcElement ? event.srcElement : event.target;
      const { page, size, total } = this.preAuditPagination;
      const isLoad = ele.scrollTop + ele.offsetHeight > ele.scrollHeight - 100
      && !this.preAuditIsLoading
      && page * size < total;
      // 加载条件为 滚动触底并且已加载数据总量page*size要小于 数据总量 total
      if (isLoad) {
        this.preAuditPagination.page += 1;
        this.preAuditIsLoading = true;
        this.pickupPlanPreAuditPage();
      }
    },
    async handleDockUpdate(rowData) {
      await this.pickupPlanDockList(moment(rowData.bookingTime));
      this.$refs.dockUpdateDialog.init(rowData);
    },
    handleSubmitDockUpdate(data) {
      this.pickupPlanDockUpdate(data);
    },
    genTime(number) {
      const addZeroBefor = (n) => (n > 9 ? n : `0${n}`);
      return `${addZeroBefor(number)}:00`;
    },
    buildDocksTableCol(basicDocks = []) {
      if (basicDocks.length <= 0) {
        return [];
      }
      return [{
        field: 'bookingTimeFormat',
        label: '时间',
        width: DOCK_TIME_CELL_WIDTH,
        fixed: true,
        align: 'center',
      }, ...basicDocks.map((item) => ({
        field: `dock${item.id}`,
        id: item.id,
        label: item.dockCode,
        width: DOCK_CELL_WIDTH,
      }))];
    },
    buildBasicDock(tableColumn) {
      const allDocks = [];
      const allDocksMap = {};
      DOCK_TIME_ROWS.forEach((rowItem) => {
        const cell = {
          bookingTimeFormat: this.genTime(rowItem),
        };
        tableColumn.forEach((headerItem) => {
          if (headerItem.id) {
            cell[`dock${headerItem.id}`] = {};
            allDocksMap[`${this.genTime(rowItem)}-${headerItem.id}`] = cell;
          }
        });
        allDocks.push(cell);
      });
      return {
        allDocks,
        allDocksMap,
      };
    },
    buildDocksLayout(basicColumn) {
      this.preAuditeddockBasicInfo.column = this.buildDocksTableCol(basicColumn);
      const all = this.buildBasicDock(this.preAuditeddockBasicInfo.column);
      this.preAuditeddockBasicInfo.data = all.allDocks;
      this.preAuditeddockBasicInfo.docksMap = all.allDocksMap;
    },
    getHour(bookingTimeFormat) {
      return moment(bookingTimeFormat).format(HH);
    },
    updateDockInfo(waitUpdateDock) {
      const dockIsTrue = this.preAuditeddockBasicInfo.docksMap[`${this.getHour(waitUpdateDock.bookingTimeFormat)}:00-${waitUpdateDock.id}`]
      && this.preAuditeddockBasicInfo.data instanceof Array;
      this.preAuditeddockBasicInfo.time = waitUpdateDock.titleBookingTimeFormat;
      if (dockIsTrue) {
        const temDocks = this.preAuditeddockBasicInfo.data;
        for (let i = 0, len = temDocks.length; i < len; i += 1) {
          const item = temDocks[i];
          const index = i;
          if (item.bookingTimeFormat === `${this.getHour(waitUpdateDock.bookingTimeFormat)}:00`) {
            // 如果这个月台已经有车 就直接把车 追加在车里
            const hasCar = this.preAuditeddockBasicInfo.data[index][`dock${waitUpdateDock.id}`].cars
            && this.preAuditeddockBasicInfo.data[index][`dock${waitUpdateDock.id}`].cars instanceof Array
            && this.preAuditeddockBasicInfo.data[index][`dock${waitUpdateDock.id}`].cars.length >= 1;
            if (hasCar) {
              this.preAuditeddockBasicInfo.data[index][`dock${waitUpdateDock.id}`] = {
                ...this.preAuditeddockBasicInfo.data[index][`dock${waitUpdateDock.id}`],
                cars: [...this.preAuditeddockBasicInfo.data[index][`dock${waitUpdateDock.id}`].cars, ...waitUpdateDock.cars],
              };
            } else {
              this.preAuditeddockBasicInfo.data[index][`dock${waitUpdateDock.id}`] = waitUpdateDock;
            }
            break;
          }
        }
      }
    },
    clearDockInfo() {
      this.preAuditeddockBasicInfo.time = '';
      if (this.preAuditeddockBasicInfo.data && this.preAuditeddockBasicInfo.data instanceof Array) {
        this.preAuditeddockBasicInfo.data.forEach((item, index) => {
          Object.keys(item).forEach((key) => {
            if (key !== 'bookingTimeFormat') {
              this.preAuditeddockBasicInfo.data[index][key] = {
                cars: [],
                dockCode: '',
                dockId: '',
                dockName: '',
                id: '',
              };
            }
          });
        });
      }
    },
    handleAuditedSearch(pagenation, formData) {
      this.auditedFromData = {
        ...formData,
        beforeDeliverTime: formData.afterDeliverTime && formData.afterDeliverTime[0] && moment(formData.afterDeliverTime[0]).startOf('day'),
        afterDeliverTime: formData.afterDeliverTime && formData.afterDeliverTime[1] && moment(formData.afterDeliverTime[1]).endOf('day'),
        beforeBookingTime: formData.afterBookingTime && formData.afterBookingTime[0] && moment(formData.afterBookingTime[0]).startOf('day'),
        afterBookingTime: formData.afterBookingTime && formData.afterBookingTime[1] && moment(formData.afterBookingTime[1]).endOf('day'),
      };
      this.pickupPlanAuditedPage(pagenation, this.auditedFromData);
    },

    async pickupPlanPreAuditPage(isFirst) {
      const resp = await pickupPlanPreAuditPage({ ...this.preAuditPagination });
      this.preAuditList = [...this.preAuditList, ...resp.records];
      this.preAuditPagination.total = resp.total;
      this.preAuditPagination.page = resp.page;
      this.preAuditPagination.size = resp.size;
      this.preAuditIsLoading = false;
      if (isFirst) {
        const bookingTime = this.preAuditList[0]?.bookingTime;
        // 如果 待审核列表存在就使用待审核列表里面的第一个 待审核订单的时间，否则就是使用当前时间
        this.pickupPlanDockList(bookingTime ? moment(bookingTime) : moment());
      }
    },
    async pickupPlanAuditedPage(pagenation, formData) {
      const usePagenation = {
        page: pagenation.page,
        size: pagenation.size,
      };
      const resp = await pickupPlanAuditedPage(usePagenation, formData);
      this.auditedTableData = resp.records;
      this.pagination.total = resp.total;
    },
    async pickupPlanDockList(bookingTime) {
      const waitUpdateDocks = await pickupPlanDockList({ bookingTime });
      const waitScrollIndex = bookingTime.format(HH) - 0;
      const bookingTimeFormat = bookingTime.format(YMDH);
      // 更新之前 清空月台车辆情况
      this.clearDockInfo();
      waitUpdateDocks.forEach((item) => {
        item.docks.forEach((dock) => {
          this.updateDockInfo({
            ...dock,
            id: dock.dockId,
            bookingTimeFormat: item.bookingTimeFormat,
            titleBookingTimeFormat: bookingTimeFormat,
          });
        });
      });
      // 如果月台列表为空 单独把月台时间更新
      if (waitUpdateDocks.length <= 0) {
        this.updateDockInfo({
          bookingTimeFormat,
          titleBookingTimeFormat: bookingTimeFormat,
        });
      }
      this.$refs.dockTable.scrollTo(waitScrollIndex);
    },
    async pickupPlanAudit() {
      // 审核之后重新刷新页面数据
      await this.refreshPageData();
    },
    /**
     * 查询月台基本信息
     */
    async warehousePlatformPage() {
      const resp = await warehousePlatformPage({ page: 1, size: 1000 }, {});
      // 预约提货 月台布局只展示发货月台
      const deliverAreaDocks = resp.records.filter((item) => item.deliverArea);
      this.deliverAreaDocks = deliverAreaDocks;
      this.buildDocksLayout(deliverAreaDocks);
      this.pickupPlanPreAuditPage(true);
    },
    async refreshPageData() {
      this.preAuditList = [];
      await this.warehousePlatformPage();
      this.pickupPlanAuditedPage(this.pagination, this.auditedFromData);
    },
    async pickupPlanDockUpdate() {
      this.$refs.dockUpdateDialog.handleClose();
      this.refreshPageData();
    },
  },
};
</script>
<style lang="scss" scoped>
.page-box {
  background: #fff;
  box-shadow: 0 1px 6px 0 rgb(0 25 55 / 10%);
  border-radius: 10px;
  padding: 24px 0 0 0;

  .pre-audit-body,
  .audited-body {
    padding: 0 24px;

    /* 44最上方导航  40 tab 高度 40所有的margin 60pxcontentpadding 50px是肉眼活动补偿 */
    height: calc(100vh - 44px - 40px - 60px - 50px);
    overflow-y: auto;

    .list-box {
      padding: 26px 24px;
      height: calc(100% - 24px);
      overflow: scroll;
      overflow-x: hidden;
      box-shadow: 0 1px 6px 0 rgba(0, 25, 55, 0.1);
      border-radius: 10px;

      & > h3 {
        margin-bottom: 20px;
      }
    }

    .table-box {
      padding: 26px 24px;
      height: calc(100% - 24px);
      background-color: #fff;
      box-shadow: 0 1px 6px 0 rgba(0, 25, 55, 0.1);
      border-radius: 10px;
    }
  }

  :deep(.el-tabs__nav-wrap) {
    padding: 0 24px;
  }
}

</style>
