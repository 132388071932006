<template>
  <div class="right-table">
    <DockTableLegend
      :time="basicDocks.time"
    />
    <DockTableView
      ref="tableView"
      :table-data="[...basicDocks.data]"
      :table-header="basicDocks.column"
    />
  </div>
</template>
<script>
import DockTableLegend from '../dockTableLegend/index.vue';
import DockTableView from '../dockTableView/index.vue';

export default {
  name: 'DockTable',
  components: {
    DockTableLegend,
    DockTableView,
  },
  filters: {
  },
  mixins: [],
  props: {
    basicDocks: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {

    };
  },
  computed: {
  },
  created() {
  },
  methods: {
    scrollTo(rowIdex) {
      this.$refs.tableView.scrollTo(rowIdex);
    },
  },
};
</script>
<style lang="scss" scoped>
.right-table {
  height: 100%;
}
</style>
